<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Empresas
      </h5>
    </b-card-header>

    <b-card-body class="mt-1">
      <table v-for="(company, i) in physicianData.companies" :key="i" class="mb-1 mt-2 mt-xl-0 w-100">
        <tr>
          <th>
            <span class="font-weight-bold">Nome</span>
          </th>
          <td class="w-100 pl-1">
            {{ company.name }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">CNPJ</span>
          </th>
          <td class="w-100 pl-1">
            {{ formatCnpj(company.cnpj) }}
          </td>
        </tr>
        <tr>
          <th>
            <span class="font-weight-bold">Status</span>
          </th>
          <td class="w-100 pl-1">
            {{ company.active ? 'Ativo' : 'Inativo' }}
          </td>
        </tr>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatCnpj } from '../../../@core/utils/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
  },
  props: {
    physicianData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    address() {
      if (Array.isArray(this.physicianData.address) && this.physicianData.address.length === 0) {
        return {}
      }
      return this.physicianData && this.physicianData.address
    },
  },
  methods: { formatCnpj },
}
</script>

<style>

</style>
