<template>
  <b-card no-body class="card-crm-data">
    <b-card-header v-if="crmData">
      <b-card-title>
        Consulta CRM {{ crmData.data.crm }}
        <small class="text-muted">
          Atualizado em {{ formatDate(crmData.created_at, { month: 'long', day: 'numeric', weekday: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
        </small>
      </b-card-title>
    </b-card-header>
    <b-card-header v-else>
      <h5>Consulta CRM não efetuada</h5>
    </b-card-header>
    <b-card-body v-if="crmData">
      <div class="row mb-1">
        <div class="col-3">
          <b-avatar
            :src="'data:image/png;base64,'+crmData.data.picture"
            size="104px"
            rounded
          />
        </div>
        <div class="col-9">
          <dl class="row">
            <dt class="col-sm-3">
              Nome
            </dt>
            <dd class="col-sm-9">
              {{ crmData.data.name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Status
            </dt>
            <dd class="col-sm-9 text-capitalize">
              <b-badge pill :variant="crmStatusVariant(crmData.data.status)">
                {{ crmData.data.status }}
              </b-badge>
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-3">
              Tipo
            </dt>
            <dd class="col-sm-9 text-capitalize">
              {{ crmData.data.type }}
            </dd>
          </dl>
        </div>
      </div>

      <dl v-if="!crmData.data.specialties" class="row">
        <dt class="col-sm-3">
          Especialidade
        </dt>
        <dd class="col-sm-9">
          {{ crmData.data.specialty || '-' }}
        </dd>
      </dl>
      <dl v-for="(specialty, i) in crmData.data.specialties" v-else :key="specialty" class="row">
        <dt class="col-sm-3">
          <span v-if="i === 0">Especialidades</span>
        </dt>
        <dd class="col-sm-9">
          {{ specialty.name }} {{ specialty.area ? `(${specialty.area})`: '' }}
          <small class="text-muted">
            {{ formatDate(specialty.validity_start_date + ' 00:00:00') }} -
            {{ specialty.validity_end_date ? formatDate(specialty.validity_end_date + ' 00:00:00') : 'atual' }}
          </small>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-3">
          Data de Registro
        </dt>
        <dd class="col-sm-9">
          {{ formatDate(crmData.data.registration_date + ' 00:00:00') }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-3">
          Gênero
        </dt>
        <dd class="col-sm-9">
          {{ crmData.data.gender || '-' }}
        </dd>
      </dl>
      <dl v-for="(area, i) in crmData.data.areas" :key="area" class="row">
        <dt class="col-sm-3">
          <span v-if="i === 0">Áreas de atuação</span>
        </dt>
        <dd class="col-sm-9">
          {{ area }}
        </dd>
      </dl>
      <dl v-for="(value, key) in crmData.data.extras" :key="key" class="row">
        <dt class="col-sm-3">
          {{ $t(`crm-fields.${key}`) }}
        </dt>
        <dd class="col-sm-9">
          {{ value || '-' }}
        </dd>
      </dl>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BCard,
  BBadge,
  BCardHeader,
  BCardBody,
  BCardTitle,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BCard,
    BBadge,
    BCardHeader,
    BCardBody,
    BCardTitle,
  },
  props: {
    crmData: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    function crmStatusVariant(status) {
      switch (status) {
        case 'regular':
          return 'light-success'
        case 'cancelado':
          return 'light-danger'
        default:
          return 'light-warning'
      }
    }

    return {
      crmStatusVariant,
      formatDate,
    }
  },
}
</script>

<style>
.card-crm-data dl {
  margin-bottom: 0!important;
}
</style>
