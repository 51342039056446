<template>
  <div>
    <back-btn />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="physicianData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching physician data
      </h4>
      <div class="alert-body">
        No physician found with this physician id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'physician-list'}"
        >
          Physician List
        </b-link>
        for other physicians.
      </div>
    </b-alert>

    <template v-if="physicianData">
      <!-- First Row -->
      <b-row class="match-height">
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <physician-view-physician-info-card :physician-data="physicianData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <physician-view-physician-address-card :physician-data="physicianData" />
        </b-col>
      </b-row>
      <!-- First Row -->
      <b-row class="match-height">
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <b-card
            no-body
            class="view-physician-accounts"
          >
            <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
              <h5 class="mb-1">
                Contas Bancárias
              </h5>
            </b-card-header>
            <b-card-body>
              <b-table
                ref="refWorkplaceListTable"
                class="position-relative"
                :items="() => physicianData.accounts"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="Nenhum registro correspondente encontrado"
              >
                <template #cell(status_checked)="data">
                  <b-media vertical-align="center">
                    <b-badge
                      pill
                      :variant="`light-${data.item.status_checked === 'approved' ? 'success' : 'danger'}`"
                      class="text-capitalize"
                    >
                      {{ data.item.status_checked === 'approved' ? 'Aprovado' : 'Recusado' }}
                    </b-badge>
                  </b-media>
                </template>
                <template #cell(company)="{ item }">
                  <div v-if="item.physician_company_cnpj">
                    <strong>{{ item.physician_company_name }}</strong>
                    <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
                  </div>
                  <div v-else>
                    -
                  </div>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <physician-view-physician-companies-card :physician-data="physicianData" />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col
          cols="12"
          xl="6"
          lg="12"
          md="12"
        >
          <physician-view-physician-crm-record-card v-if="physicianData" :crm-data="physicianData.crm_record" />
        </b-col>

      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BMedia, BTable, BBadge, BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue'
import BackBtn from '@core/components/navigation/BackBtn.vue'
import PhysicianViewPhysicianCrmRecordCard from '@/views/physician/physician-view/PhysicianViewPhysicianCrmRecordCard.vue'
import physicianStoreModule from '../physicianStoreModule'
import PhysicianViewPhysicianInfoCard from './PhysicianViewPhysicianInfoCard.vue'
import PhysicianViewPhysicianAddressCard from './PhysicianViewPhysicianAddressCard.vue'
import PhysicianViewPhysicianCompaniesCard from './PhysicianViewPhysicianCompaniesCard.vue'

export default {
  components: {
    BBadge,
    BTable,
    BMedia,
    BCard,
    BCardBody,
    BCardHeader,
    PhysicianViewPhysicianCrmRecordCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BackBtn,
    // Local Components
    PhysicianViewPhysicianInfoCard,
    PhysicianViewPhysicianAddressCard,
    PhysicianViewPhysicianCompaniesCard,
  },
  setup() {
    const physicianData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, physicianStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-physician/fetchPhysician', { id: router.currentRoute.params.id })
      .then(response => { physicianData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          physicianData.value = undefined
        }
      })

    const tableColumns = [
      { key: 'bank_name', label: 'Banco' },
      {
        key: 'account_type',
        label: 'Tipo',
        formatter: v => (v === 'savings_account' ? 'Poupança' : 'Corrente'),
      },
      { key: 'branch_number', label: 'Nº Agência' },
      { key: 'branch_code', label: 'Dígito da Agência' },
      { key: 'account_number', label: 'conta' },
      { key: 'account_code', label: 'Dígito' },
      { key: 'status_checked', label: 'Status' },
      { key: 'person_type', label: 'Tipo Pessoa' },
      { key: 'company', label: 'Empresa' },
      { key: 'favorite', label: 'Favorito', formatter: v => (v ? 'Sim' : 'Não') },
    ]

    return {
      tableColumns,
      physicianData,
    }
  },
}
</script>

<style>
.view-physician-accounts .card-body {
  padding: 0;
}
</style>
